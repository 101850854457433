const pricingPlans = [
  {
    id: "price_1PycsEJlIvt55eMlzHaHkd9a",
    title: "1 Time Setup",
    price: "$990",
    items: [
      "Training manual",
      "1 time online meet (1 hour)",
      "1 roller",
      "500 pcs sheets (4’’ x 4’’)",
      "App download",
      "3 month key (1 device only)",
      "Border file",
    ],
  },
  {
    id: "price_1PycseJlIvt55eMlDRFmQDfR",
    title: "Yearly License",
    price: "$1500",
    items: [
      "Yearly subscription",
      "Unlimited events usage for 365 days",
      "1 device",
    ],
  },
  {
    id: "price_1Pyct6JlIvt55eMlTbd4SHN9",
    title: "Monthly License",
    price: "$150",
    items: [
      "Monthly subscription",
      "Unlimited events usage for 30 days",
      "1 device",
    ],
  },
  {
    id: "price_1PyctZJlIvt55eMlorJURoTl",
    title: "Daily License",
    price: "$40",
    items: [
      "Daily subscription",
      "Unlimited events usage for 24 hours",
      "1 device",
    ],
  },
];

export { pricingPlans };
